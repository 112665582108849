import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
      // Root
      path: '/:company/:propertyID',
      name: 'Property',
      component: () => import('../views/PropertyView.vue'),
  },
  {
    // Root
    path: '/preview/:company/:propertyID',
    name: 'PropertyPreview',
    component: () => import('../views/PropertyPreviewView.vue'),
},
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/static/404.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: () => import('../views/static/PrivacyPolicy.vue')
  }
]
  

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
