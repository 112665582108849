import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import VueSocialSharing from 'vue-social-sharing'


loadFonts()

Amplify.configure(awsExports);

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueSocialSharing, {
    networks:{
      'instagram': "https://www.instagram.com/"
    }
  })
  .mount('#app')
