// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const defaultTheme = {
  dark: false,
  colors: {
    primary: '#eb5c37',
    secondary: '#40424c',
    tertiary: '#f0f0f0',
    title: "#9e9e9e",
    featureHighlight: "#9e9e9e",
    dialogColor: '#eb5c37',
    accent: '#9e9e9e',
    error: '#b71c1c',
    tabColor: '#eb5c37',
    tableColor: '',
    fontColor: '#36454F',
    'surface-variant': '#ffff00',
    'on-surface-variant': '#ffffff',
  },
  options: {
    customProperties: true
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'defaultTheme',
    themes: {
      defaultTheme,
    }
  }
})
