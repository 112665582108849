import { createStore } from 'vuex'
import { API } from "aws-amplify";
import Cookies from "js-cookie";
import { displayAddress } from '@/jsfunctions/displayAddressFunction';
export default createStore({
  state: {
    companyUrls: '',
    property: '',
    additionalProperties: '',
    loading: true,
    eer: '',
    eir: '',
    cookieConsent: '',
    shortlist: [],
    previouslyVisited: []
  },
  getters: {
    additionalProperties(state){
      return state.additionalProperties;
    },
    companyURLs(state) {
      return state.companyUrls
    },
    property(state){
      return state.property
    },
    eer(state){
      return state.eer
    },
    eir(state){
      return state.eir
    },
    validKeyFeatures(state){
      return state.property.PropertyDetails.KeyFeatures.filter(function(entry)
      {
        if(entry !== null && entry.HasFeature === true)
        return entry
      }
      );
    },
    validAdditionalFeatures(state){
      return state.property.PropertyDetails.FurtherInformation.filter(function(entry)
      {
        if(entry !== null && entry.HasFeature === true)
        return entry
      }
      );
    },
    validOutsideFeatures(state){
      return state.property.PropertyDetails.OutsideSpace.filter(function(entry)
      {
        if(entry !== null && entry.HasFeature === true)
        return entry
      }
      );
    },
    validParkingFeatures(state){
      return state.property.PropertyDetails.Parking.filter(function(entry)
      {
        if(entry !== null && entry.HasFeature === true)
        return entry
      }
      );
    },
    cookieConsent(state){
      return state.cookieConsent
    },
    rooms(state){
      return state.property.Rooms;
    },
    propertyInShortlist(state){
      state.property.OID;  
      var index = state.shortlist.map(e => e.OID).indexOf(state.property.OID);
      return index > -1;
    },
    shortlistProperties(state){
      return state.shortlist;
    },
    previouslyVisitedProperties(state){
      return state.previouslyVisited;
    }
  },
  mutations: {
    SET_COMPANY_URLS(state, data) {
      state.companyUrls = data
    },
    SET_PROPERTY(state, data) {
      state.property = data
    },
    FINISH_LOADING(state, data) {
      state.loading = data
    },
    SET_EER(state, data) {
      state.eer = data
      
    },
    SET_EIR(state, data){
      state.eir = data
    },
    SET_THEME(state, data){
      state.theme = data;
    },
    SET_ADDITIONAL_PROPERTIES(state, data){
      state.additionalProperties = data;
    },
    // Refreshes the cookie:-
    SET_COOKIE_PERMISSIONS(state, data){
      state.cookieConsent = data;
      const dataString = JSON.stringify(data);
      Cookies.set('agentOS-cookie-consent', dataString, { expires : 365 })
    },
    // Does not refresh the cookie, only to be used in page load:-
    SET_COOKIE_PERMISSIONS_IN_STATE(state, dataString){
      const data = JSON.parse(dataString)
      state.cookieConsent = data;
    },
    ADD_TO_SHORTLIST(state, data){
      state.shortlist.unshift(data);
      const datastring = JSON.stringify(state.shortlist);
      Cookies.set('agentOS-property-shortlist', datastring, {expires: 365})
    },
    ADD_TO_PREVIOUSLY_VIEWED(state, data){
      var index = state.previouslyVisited.map(e => e.OID).indexOf(data.OID);
      if(index > -1){
        state.previouslyVisited.splice(index, 1);
      }
      state.previouslyVisited.unshift(data);
      const datastring = JSON.stringify(state.previouslyVisited);
      Cookies.set('agentOS-property-p-v', datastring, {expires: 1})
    },
    REMOVE_FROM_SHORTLIST(state, data){
      var index = state.shortlist.map(e => e.OID).indexOf(data.OID);
      if(index > -1){
        state.shortlist.splice(index, 1);
      }
      const datastring = JSON.stringify(state.shortlist)
      Cookies.set('agentOS-property-shortlist', datastring, {expires: 365});
    },
    SET_SHORTLIST(state){
      state.shortlist = [];
    },
    SET_SHORTLIST_IN_STATE(state, datastring){
      const data = JSON.parse(datastring)
      state.shortlist = data;
    },
    SET_PREVIOUSLY_VISITED(state){
      state.previouslyVisited = [];
    },
    SET_PREVIOUSLY_VISITED_IN_STATE(state, datastring){
      const data = JSON.parse(datastring)
      state.previouslyVisited = data;
    }
  },
  actions: {
    getCompanyUrls({commit}, params) {
      
      API.post("micrositeRest", "/company/urls", params).then((res) => {
        if (res.status === "200") {
          commit('SET_COMPANY_URLS', res.data)
        }
      });
    },

    getPropertyPreview({commit}, payload){
      // Check if property ID is a valid Guid, if it is request property from API:-
      if(payload.body.propertyID.includes("sale") || payload.body.propertyID.includes("inst")){
        API.post("micrositeRest", "/property/preview", payload).then((res) => {
          document.title = displayAddress(res.data.OverviewDetails.Address);
          commit('SET_PROPERTY', res.data)
          commit('FINISH_LOADING', false)
      }).catch(() =>
      {
        window.location.replace("/404");
      })
      }
    },


    getProperty({commit, dispatch}, payload){
      // Check if property ID is a valid Guid, if it is request property from API:-
      if(payload.body.propertyID.includes("sale") || payload.body.propertyID.includes("inst")){
        API.post("micrositeRest", "/property", payload).then((res) => {
          document.title = displayAddress(res.data.OverviewDetails.Address);
          commit('SET_PROPERTY', res.data)
          // Check if property ID is a valid Guid, if it is request property from API:-
          if(payload.body.propertyID.includes("sale") || payload.body.propertyID.includes("inst")){
            const eerpayload = {body : {
              clientName : payload.body.clientName,
              propertyID : res.data.OID,
              graphType : "EnergyEfficiency"
            }}
            API.post("micrositeRest", "/epc/download", eerpayload)
            .then((eerRes) => {
              commit('SET_EER', eerRes.data)
            })
          }
          if(payload.body.propertyID.includes("sale") || payload.body.propertyID.includes("inst")){
            const eirpayload = {body : {
              clientName : payload.body.clientName,
              propertyID : res.data.OID,
              graphType : "EnvironmentalImpact"
            }}
            API.post("micrositeRest", "/epc/download", eirpayload)
            .then((eirRes) => {
              commit('SET_EIR', eirRes.data)
            })
          }
          // Not property ID, just set eer to be null
          else{
            commit('SET_EIR', '')
          }    
          let historicalPayload = {
            OID: res.data.OID,
            Address: res.data.OverviewDetails.Address,
            Price: res.data.OverviewDetails.Price,
            Bedrooms: res.data.PropertyDetails.BedroomCount,
            Bathrooms: res.data.PropertyDetails.BathroomCount,
            URL: window.location.href,
            MainImage: res.data.OverviewDetails.BackgroundImage,
            DateAdded: new Date().toLocaleDateString(),
            ListingType: res.data.ListingType
          }
          if(res.data.ListingType === "Letting"){
            historicalPayload.Price = historicalPayload.Price + " " + res.data.OverviewDetails.SaleBy
          }
            dispatch('addToPreviouslVisited', 
              historicalPayload
            )
          }).catch(() => {
          window.location.replace("/404");
        })
        commit('FINISH_LOADING', false)
      }
      // If not GUID, just redirect to error to reduce errors in API app pool:-
      else{
        window.location.replace("/404");
      }
    },
    getSimilarProperties({commit}, payload){
        if(payload.body.propertyID.includes("sale") || payload.body.propertyID.includes("inst")){
          API.post("micrositeRest", "/property/similar", payload).then((res) => {
            
            commit('SET_ADDITIONAL_PROPERTIES', res.data)
        })
      }
      // Set to null, the URL is wrong, save the app pool:-
      else{
        commit('SET_ADDITIONAL_PROPERTIES', '')
      }
    },
    acceptCookies({commit}, payload){
      commit('SET_COOKIE_PERMISSIONS', payload);
    },
    loadCookiePermissions({commit}){
      var cookies = Cookies.get('agentOS-cookie-consent');
      if(cookies !== undefined)
      commit('SET_COOKIE_PERMISSIONS_IN_STATE', cookies)
      else
      {
        commit('SET_COOKIE_PERMISSIONS', { removeCookieNotice: false, necessaryCookies : false, analyticsCookies: false, preferenceCookies: false, thirdPartyCookies: false } )

      }
    },
    loadShortlist({commit}){
      var cookies = Cookies.get('agentOS-property-shortlist');
      if(cookies !== undefined){
      commit('SET_SHORTLIST_IN_STATE', cookies)}
      else
      {
        commit('SET_SHORTLIST')
      }
    },
    loadPreviouslyVisited({commit}){
      var cookies = Cookies.get('agentOS-property-p-v');
      if(cookies !== undefined){
      commit('SET_PREVIOUSLY_VISITED_IN_STATE', cookies)}
      else
      {
        commit('SET_PREVIOUSLY_VISITED')
      }
    },
    addToShortlist({commit}, payload){
      if( this.state.cookieConsent !== undefined && this.state.cookieConsent.necessaryCookies === true){
        commit("ADD_TO_SHORTLIST", payload);
      }
      else{
        return null;
      }
    },
    addToPreviouslVisited({commit}, payload){
      if( this.state.cookieConsent !== undefined && this.state.cookieConsent.necessaryCookies === true){
        commit("ADD_TO_PREVIOUSLY_VIEWED", payload);
      }
      else{
        return null;
      }
    },
    removeFromShortlist({commit}, payload){
      if( this.state.cookieConsent !== undefined && this.state.cookieConsent.necessaryCookies === true){
        commit("REMOVE_FROM_SHORTLIST", payload);
      }
      else{
        return null;
      } 
    },
  },
  modules: {
  }
});
