export function displayAddress(address){
    if(address === undefined){
        return "";
    }
    else{
  let addressString = address.Address1;
  if(addressString === ""){
    addressString = address.Address2;
  }
  else if(address.Address2 !== ""){
    addressString = addressString + ", " + address.Address2;
  }
  if(addressString === ""){
    addressString = address.Address3;
  }
  else if(address.Address3 !== ""){
    addressString = addressString + ", " + address.Address3
  }
  if(addressString === ""){
    addressString = censorPostcode(address.Postcode)
  }
  else if(address.Postcode){
    addressString = addressString + ", " + censorPostcode(address.Postcode)
  }
  return addressString;
    }
}
function censorPostcode(postcode){
    return postcode.split(" ")[0];
}