<template>
  <v-app>
      <router-view/>
  </v-app>
</template>

<script>

import { defineComponent } from 'vue';
export default defineComponent({
  name: "App",
  components: {
}
})
</script>
<style>
.pointer:hover{
  cursor: pointer!important;
}
</style>
